import axios from 'axios'


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axios.interceptors.response.use(response => response, async error => {
    const status = error?.response?.status

    if (status === 419) {
        // Refresh our session token
        await axios.get('/csrf-token')

        // Return a new request using the original request's configuration
        return axios(error.response.config)

    } else if (status === 401) {
        if (error.response.data?.loginUrl) {
            window.location.href = error.response.data.loginUrl


            if ((window as any).doctorWorkplace) {
                const { useWorkPlaceStore } = (await import('@doctor-workplace/stores/workplace'))
                const workplace = useWorkPlaceStore()

                workplace.redirectingToEveselibaLogin = true
            }
        }
    }

    return Promise.reject(error)
})


type HttpConfig = {
    data?: any,
    useFormData?: boolean,
    success?: (response: any) => void,
    error?: (error: any, statusCode?: number) => void,
    always?: () => void,
}


export default class Http {
    static async request(method: string, url: string, config: HttpConfig = {}) {

        const requestData = config.useFormData ? toFormData(config.data) : config.data

        const axiosPromise = axios({
            method,
            url,
            [method === 'GET' ? 'params' : 'data']: requestData,
        })

        if ('success' in config || 'error' in config || 'always' in config) {
            try {
                const response = await axiosPromise

                if (config.success instanceof Function) {
                    config.success(response.data)
                }
            } catch (error) {
                console.error(error)

                let statusCode = undefined

                if (axios.isAxiosError(error)) {
                    statusCode = error.response?.status
                }

                if (config.error instanceof Function) {
                    config.error(error, statusCode)
                }

            } finally {
                if (config.always instanceof Function) {
                    config.always()
                }
            }

        } else {
            const response = await axiosPromise

            return response.data
        }
    }

    static post(url: string, config: HttpConfig = {}) {
        return this.request('POST', url, config)
    }

    static get(url: string, config: HttpConfig = {}) {
        return this.request('GET', url, config)
    }
}



function toFormData(obj: Record<string, any>, form?: FormData, namespace?: string) {
    const fd = form || new FormData()
    let formKey

    for (const property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property) && obj[property]) {
            if (namespace) {
                formKey = namespace + '[' + property + ']'
            } else {
                formKey = property
            }

            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString())

            } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                toFormData(obj[property], fd, formKey)

            } else {
                // string|File
                fd.append(formKey, obj[property])
            }
        }
    }

    return fd
}
